@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full;
  }

  body {
    @apply flex flex-col bg-white text-default antialiased;
  }

  a {
    @apply text-primary decoration-1 hover:underline;
  }

  select {
    @apply block w-full rounded-lg border border-gray-300 bg-white p-2.5 focus:border-primary focus:ring-primary;
  }
  select:not([size]) {
    appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJjc3MtaTZkenExIj48cG9seWxpbmUgcG9pbnRzPSI2IDkgMTIgMTUgMTggOSI+PC9wb2x5bGluZT48L3N2Zz4=);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    padding-right: 2.5rem;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center whitespace-nowrap rounded-lg px-4 py-3 text-base font-semibold transition-all duration-300 ease-in-out hover:no-underline focus:outline-none;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-primaryDark disabled:bg-primaryLight;
  }

  .btn-accent {
    @apply bg-accent text-white hover:bg-accentDark disabled:bg-accentLight;
  }

  .btn-outline-primary {
    @apply border border-primary bg-transparent text-primary hover:border-primaryDark hover:text-primaryDark disabled:border-primaryLight disabled:text-primaryLight;
  }

  .btn-outline-accent {
    @apply border border-accent bg-transparent text-accent hover:border-accentDark hover:text-accentDark disabled:border-accentLight disabled:text-accentLight;
  }

  .btn-text-primary {
    @apply bg-transparent text-primary hover:text-primaryDark disabled:text-primaryLight;
  }

  .btn-text-accent {
    @apply bg-transparent text-accent hover:bg-accentLight disabled:text-accentLight;
  }

  .btn-sm {
    @apply rounded-md px-3 py-2 text-sm;
  }

  .btn-lg {
    @apply rounded-xl px-5 py-4;
  }
}
